
import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class BannerPostingViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'SPONSOR', text: '스폰서' },
        { id: 'PLUS', text: '플러스' },
      ],
      option:{
        isCount: false,
        queryKeyName: 'tabValue'
      },
      value: 'SPONSOR',
    };
    this.boardData = {
      drawDataList: [
        {
          title:'순서',
          width:'58px',
          icon:{
            iconName:'drag',
            size:14,
            style:{margin:'12px'}
          },
        },
        {
          title:'No.',
          width:'50px',
          value:'rowIndex',
        },
        {
          title:'position',
          width:'50px',
          value:'position',
        },
        {
          title:'섬네일',
          width:'80px',
          value:'thumbnailImageUrl',
          img:{
            size:'contain',
            width:'60px',
            height:'60px',
            position:{
              x:'center',
              y:'center',
            }
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isAlignLeft: true,
          emptyValueText:'-',
          class: { name:'td_title' },
        },
        {
          title:'mapStyle',
          width:'94px',
          value:'mapStyle',
        },
        // {
        //   title:'내리기/<br/>올리기',
        //   width:'84px',
        //   value:'disabled',
        //   buttonCondition: [
        //     {
        //       condition:true,
        //       text:'올리기',
        //       size:'small',
        //       style:'secondary_border',
        //       disabled:false,
        //       onClickEvent: (data,rowIndex) => this.onClickUnDisabled(data,rowIndex)
        //     },
        //     {
        //       condition:false,
        //       text:'내리기',
        //       size:'small',
        //       style:'fourthly_border',
        //       disabled:false,
        //       onClickEvent: (data,rowIndex) => this.onClickDisabled(data,rowIndex)
        //     },
        //   ],
        // },
      ],
      option:{
        isTotal: false,
        isSize: false,
        isDrag: true,
        dragHandle:'.icon_drag',
        onEndDrag:(e,data) => this.onEndDrag(e,data)
      }
    };
    this.totalBannerPostingCount = 40;
    this.dataList = {
      ALL:[],
      PLUS:[],
      SPONSOR:[],
    };
    this.searchParams = {
      page:0,
      pageSize:40,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isDisabledResetBtn = true;
    this.isBannerSourceSearchPopup = false;
    this.editIndex = null;

    this.positionPutCount = 0;
  }
  init(){
    this.getData();
  }

  onUpdateTab(value){
    console.log(value)
  }

  onClickReset(){
    this.isDisabledResetBtn = true;
    this.getData();
  }
  onEndDrag(e){
  //   if( e.newIndex === e.oldIndex ) return;
  //   console.log(e, e.oldIndex, e.newIndex)
  }
  // 올리기
  onClickUnDisabled(data,rowIndex){
    this.dataList[this.tabData.value][rowIndex].disabled = false;
    this.dataList[this.tabData.value] = this.setDataListSort(this.tabData.value);
  }
  // 내리기
  onClickDisabled(data,rowIndex){
    this.dataList[this.tabData.value][rowIndex].disabled = true;
    this.dataList[this.tabData.value] = this.setDataListSort(this.tabData.value);
  }
  setDataListSort(tabId, dataList) {
    const currentDataList = dataList && dataList.length > 0 ? dataList : this.dataList[tabId];
    const disabledList = currentDataList.filter(item => item.disabled);
    const unDisabledList = currentDataList.filter(item => !item.disabled);
    unDisabledList.sort(function(a, b) {
      return a.position - b.position;
    });
    var result = [];
    if(disabledList.length > 0) result = unDisabledList.concat(disabledList);
    else result = unDisabledList;
    return result;
  }
  setDataListByMapType(resultData, mapType){
    const mapTypeFilterList = resultData.filter(
      item => item.mapType === mapType,
    );
    this.dataList[mapType] = this.setDataListSort(mapType, mapTypeFilterList);
  }
  onClickEdit(data, rowIndex) {
    this.editIndex = rowIndex;
    this.isBannerSourceSearchPopup = true;
  }
  onClickCloseBannerSourceSearchPopup(){
    this.editIndex = null;
    this.isBannerSourceSearchPopup = false;
  }
 
  onClickComplete(){
    console.log('onClickComplete')
    this.dataList[this.tabData.value].forEach((item,index) => {
      this.postData(item.id, item.position, index + 1);
    })
  }
  getData(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.MAP_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        // disabled
        const resultData = success.data.body.maps.map(item => {
          item.disabled = item.position === 0;
          return item;
        });
        this.dataList.ALL = resultData;
        this.setDataListByMapType(resultData, 'PLUS');
        this.setDataListByMapType(resultData, 'SPONSOR');
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  setData(resultData){
    resultData = resultData.map(item => {
      item.isEmpty = false;
      return item
    })
    if(resultData.length < this.totalBannerPostingCount){
      const addDataLength = this.totalBannerPostingCount - resultData.length;
      let newIndex = resultData.length +1;
      for(var i = 0; i < addDataLength; i++){
        let newData = {
          id : `new${newIndex}`,
          title : '',
          viewCount : '',
          bannerImageUrl : '',
          startAt : '',
          endAt : '',
          isEmpty: true
        }
        resultData.push(newData)
        newIndex += 1;
      }
    }
    this.dataList = resultData
    this.paginationData.totalCount = this.totalBannerPostingCount;
    this.paginationData.totalPage = 1;
  }
  postData(id, oldPosition, newPosition){
    console.log('postData',id, oldPosition, newPosition)
    this.positionPutCount += 1;
    if(oldPosition === newPosition) return;
    const path = `${apiPath.MAP_POSITION.format(id)}`;
    const addByCategory = (this.tabData.dataList.findIndex(item => item.id === this.tabData.value)) * 100;
    const data = {
      oldPosition: oldPosition,
      newPosition: newPosition + addByCategory,
      badRequest: true
    };
    PUT_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      if(this.positionPutCount === this.dataList[this.tabData.value].length +1){
        this.positionPutCount = 0;
        this.getData();
        store.dispatch('commonToast/fetchToastStart', `순서가 변경되었습니다.`);
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}